import styles from './TeamStreamControl.module.scss';
import classnames from 'classnames';
import { IconButton, Text } from '@veo/web-design-system';

const SCORE_INCREMENT = 1;

type TeamStreamControlProps = {
  score: number;
  onChangeScore: (newScore: number) => void;
};

export function TeamStreamControl({
  score,
  onChangeScore,
}: TeamStreamControlProps) {
  return (
    <div className={classnames(styles['container'])}>
      <IconButton
        onClick={() => onChangeScore(score + SCORE_INCREMENT)}
        icon={'plus'}
        variant={'outlined'}
        size={'sm'}
      />
      <Text className={classnames(styles['text'], styles['text__score'])}>
        {score}
      </Text>
      <IconButton
        onClick={() => onChangeScore(score - SCORE_INCREMENT)}
        icon={'minus'}
        variant={'outlined'}
        size={'sm'}
        disabled={score <= 0}
      />
    </div>
  );
}
