import React, { createContext, useContext } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

type AnalyticsContextType<T extends string> = {
  track: (eventName: T, properties?: Record<string, unknown>) => void;
};

type Configuration = {
  segmentWriteKey?: string;
};

type Props = {
  children: React.ReactNode;
  configuration: Configuration;
};

const AnalyticsContext = createContext<AnalyticsContextType<string>>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  track: (_: string) => {
    // Do nothing
  },
} as AnalyticsContextType<string>);

export function AnalyticsProvider({ children, configuration }: Props) {
  function loadSegmentScript() {
    if (!configuration.segmentWriteKey) {
      return;
    }

    try {
      return AnalyticsBrowser.load({
        writeKey: configuration.segmentWriteKey,
      });
    } catch (error) {
      console.error('Segment analytics did not load');
    }
  }

  const analytics = loadSegmentScript();

  async function track(
    eventName: string,
    properties?: Record<string, unknown>,
  ) {
    if (!analytics) {
      return;
    }

    analytics.track(eventName, { source: 'VeoLive', ...properties });
  }

  return (
    <AnalyticsContext.Provider value={{ track }}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics<T extends string>() {
  const context = useContext(AnalyticsContext);

  return {
    track: context.track as (
      eventName: T,
      properties?: Record<string, unknown>,
    ) => void,
  };
}
