import { environment } from '../environments/environment';

export async function fetchFromLiveService<InputType, ResultType>(
  partialURL: string,
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' = 'GET',
  body?: InputType,
): Promise<ResultType> {
  const bearerToken = getTokenFromStorage();
  if (!bearerToken) {
    throw new Error('Only authenticated users can access this endpoint.');
  }

  const result = await fetch(`${environment.liveAPIEndpoint}/${partialURL}`, {
    method,
    body: body && JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
  });

  if (!result.ok) {
    throw new Error(result.statusText);
  }

  return result.json();
}

export function getTokenFromStorage() {
  const value = window.sessionStorage.getItem(
    `oidc.user:${environment.oicd_authority}:${environment.oicd_client_id}`,
  );

  if (!value) {
    return null;
  }

  try {
    const user = JSON.parse(value) as { access_token: string };

    return user.access_token;
  } catch (err) {
    return null;
  }
}
