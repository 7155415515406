import { Text, Toggle } from '@veo/web-design-system';
import styles from './PromotionalOverlaySection.module.scss';
import { useId } from 'react';
import classNames from 'classnames';

const NO_BANNER_TEXT =
  'No banners have been added. A Club Admin can add banners via the Veo Live settings on app.veo.co';

type PromotionalOverlaySectionProps = {
  ImgSVG: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  enabled: boolean;
  onChange: (newState: boolean) => void;
  hasImage: boolean;
};

export function PromotionalOverlaySection({
  title,
  ImgSVG,
  enabled,
  onChange,
  hasImage,
}: PromotionalOverlaySectionProps) {
  const toggleId = useId();

  return (
    <div className={styles['promotional-container']}>
      <div
        className={classNames({
          [styles['promotional-container-body']]: true,
          [styles['promotional-container-body__off']]: !enabled,
        })}
      >
        <Text
          size={'overline'}
          weight="bold"
          className={styles['promotional-container-text']}
          // eslint-disable-next-line i18next/no-literal-string
        >
          {title}
        </Text>
        <ImgSVG />
      </div>
      {hasImage ? (
        <Toggle
          onChange={() => onChange(!enabled)}
          checked={enabled}
          id={toggleId}
          label={enabled ? 'On' : 'Off'}
        />
      ) : (
        <Text
          size={'overline'}
          weight="bold"
          // eslint-disable-next-line i18next/no-literal-string
        >
          {NO_BANNER_TEXT}
        </Text>
      )}
    </div>
  );
}
