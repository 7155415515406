import styles from './Skeleton.module.scss';

type SkeletonProps = {
  width: string;
  height: string;
};

export function Skeleton({ width, height }: SkeletonProps) {
  return <div style={{ width, height }} className={styles['container']}></div>;
}
