import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

export function AuthLogout() {
  const { clearStaleState, removeUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    removeUser();
    clearStaleState();
    navigate('/');
  }, [removeUser, navigate, clearStaleState]);

  return <div />;
}
