export const environment = {
  production: false,
  liveAPIEndpoint: 'https://api-staging.live.veo.co',

  oicd_authority: 'https://auth-dev.veo.co/oidc',
  oicd_client_id: '4cK0hyJCaZQW-rmGjfefv',

  segmentWriteKey: undefined,
  sentryDsn:
    'https://710f7ad45e97ef435f1905c5cb7bada0@o4507962988363776.ingest.de.sentry.io/4508279704059984',
};
