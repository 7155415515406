import { Route, Routes } from 'react-router-dom';
import { StreamControl } from './stream-control';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { StreamsList } from './stream-list';

export function AuthenticatedApp() {
  const { isAuthenticated, signinRedirect, activeNavigator } = useAuth();

  useEffect(() => {
    if (!isAuthenticated && !activeNavigator) {
      signinRedirect({ state: window.location.pathname });
    }
  }, [isAuthenticated, signinRedirect, activeNavigator]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Routes>
      <Route path="/stream/:streamId" element={<StreamControl />} />
      <Route path="/" element={<StreamsList />} />
    </Routes>
  );
}
