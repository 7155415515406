export const scoreboardPreviewPlaceholder = {
  clock: '#CLOCK',
  teamShortName: {
    home: '#HOME_TEAM',
    away: '#AWAY_TEAM',
  },
  score: {
    home: '#HOME_SCORE',
    away: '#AWAY_SCORE',
  },
};

export const scoreboardPreviewSvgAsString = `
<svg width="100%" viewBox="0 0 619 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_555_564)">
<g clip-path="url(#clip0_555_564)">
<rect x="8" width="197.665" height="63.4774" rx="19.5315" fill="#273027"/>
<path d="M34.3238 20.9316H32.0497V34.4442C30.733 33.8164 29.7755 32.4412 29.7755 30.8568V20.9316H27.5312V30.8568C27.5312 34.2947 30.3141 37.1049 33.7553 37.1049H45.0962V34.8329H34.3238V20.9316ZM27.5312 35.7895C27.7108 39.0481 30.4338 41.649 33.7553 41.649H45.0663V39.3769H33.7553C31.7804 39.3769 28.9376 38.4502 27.5312 35.7895Z" fill="url(#paint0_linear_555_564)"/>
<path d="M52.1252 20.9316V34.4442C50.8085 33.8164 49.851 32.4412 49.851 30.8568V20.9316H47.6067V30.8568C47.6067 34.2947 50.3896 37.1049 53.8308 37.1049H54.3993V20.9316H52.1252ZM47.6067 35.7895C47.7863 39.0481 50.5093 41.649 53.8308 41.649H54.3993V39.3769H53.8308C51.8558 39.3769 49.0131 38.4502 47.6067 35.7895Z" fill="url(#paint1_linear_555_564)"/>
<path d="M76.6456 20.7522C76.0771 22.7552 75.0896 26.0138 74.0124 29.2425C73.0549 31.9928 72.217 34.235 71.4988 35.9689C70.9602 37.0451 69.9728 37.3142 69.1648 35.6101C69.2845 35.3411 69.4042 35.072 69.5239 34.7731C70.272 32.9794 71.1398 30.6475 72.0973 27.7776C72.9651 25.1767 73.7431 22.5758 74.2817 20.7522H71.9178C71.439 22.3367 70.8106 24.3396 70.1523 26.4024C69.3144 28.9136 68.5065 31.2155 67.908 32.7402C67.908 32.7402 67.908 32.7402 67.908 32.7103C66.3221 28.7641 63.9582 20.7522 63.9582 20.7522H61.5942C61.5942 20.7522 63.4794 27.8075 66.5914 35.4607C66.9804 36.4472 67.549 37.4038 67.908 37.942C69.5538 40.2738 72.2469 40.1542 73.6234 36.776C74.3715 35.0122 75.2093 32.7402 76.1669 29.9599C77.3937 26.2828 78.471 22.6655 79.0395 20.7522H76.6456ZM66.2922 39.5264C65.7536 38.8986 65.2149 37.9121 64.5866 36.5369C63.7786 34.7432 62.851 32.2918 61.8336 29.2126C60.7564 26.0138 59.7988 22.7851 59.2303 20.7522H56.8664C57.405 22.6954 58.4523 26.2828 59.6791 29.93C60.7265 33.0989 61.684 35.6101 62.4919 37.4636C63.0605 38.6893 63.5692 39.646 64.0779 40.3635C65.1551 41.9479 66.4717 42.7252 67.938 42.7252C68.6262 42.7252 69.6735 42.5458 70.6909 41.6191C70.7208 41.5892 70.7508 41.5593 70.8106 41.5294C69.0451 41.7685 67.6088 41.2005 66.2922 39.5264Z" fill="url(#paint2_linear_555_564)"/>
<path d="M87.022 20.9316C83.7005 20.9316 80.9775 23.5325 80.798 26.7911C82.2044 24.1304 85.0471 23.2036 87.022 23.2036H98.333V20.9316H87.022ZM80.798 31.2753V35.4009C80.798 38.8388 83.5808 41.649 87.022 41.649H98.333V39.3769H87.022C84.8376 39.3769 83.0422 37.5832 83.0422 35.4009V31.7238C83.0422 30.1393 83.9698 28.7641 85.3164 28.1363V35.1318C85.3164 36.208 86.1842 37.1049 87.2614 37.1049H87.4409H98.333V34.8329H87.5905V32.4113H91.6601V30.1393H87.5905V27.7477H98.333V25.4757H87.022C83.7005 25.4757 81.0074 28.0467 80.798 31.2753Z" fill="url(#paint3_linear_555_564)"/>
<text fill="#F7F6F3" xml:space="preserve" style="white-space: pre" font-family="Protokoll" font-size="26" font-weight="500" letter-spacing="0em"><tspan x="119.346" y="39.6687" id="clock">#CLOCK</tspan></text>
</g>
<path d="M226.465 19.5315C226.465 8.74456 235.209 0 245.996 0H343.654V63.4774H245.996C235.209 63.4774 226.465 54.7329 226.465 43.9459V19.5315Z" fill="#273027"/>
<text fill="#F7F6F3" xml:space="preserve" style="white-space: pre" font-family="Protokoll" font-size="26" font-weight="bold" letter-spacing="0em"><tspan x="259.56" y="39.6687" id="home_team">#HOME_TEAM</tspan></text>
<rect width="150" height="63" transform="translate(343.654)" fill="#FAF9F7"/>
<text fill="#273027" xml:space="preserve" style="white-space: pre" font-family="Protokoll" font-size="32" font-weight="bold" letter-spacing="0em" text-anchor="end"><tspan x="396.186" y="41.26" id="home_score">#HOME_SCORE</tspan></text>
<text fill="#273027" xml:space="preserve" style="white-space: pre" font-family="Protokoll" font-size="32" font-weight="bold" letter-spacing="0em"><tspan x="412.529" y="41.26">-</tspan></text>
<text fill="#273027" xml:space="preserve" style="white-space: pre" font-family="Protokoll" font-size="32" font-weight="bold" letter-spacing="0em" text-anchor="start"><tspan x="439.154" y="41.26" id="away_score">#AWAY_SCORE</tspan></text>
<path d="M493.654 0H591.312C602.099 0 610.843 8.74456 610.843 19.5315V43.9459C610.843 54.7329 602.099 63.4774 591.312 63.4774H493.654V0Z" fill="#273027"/>
<text fill="#F7F6F3" xml:space="preserve" style="white-space: pre" font-family="Protokoll" font-size="26" font-weight="bold" letter-spacing="0em"><tspan x="526.249" y="39.6687" id="away_team">#AWAY_TEAM</tspan></text>
</g>
<defs>
<filter id="filter0_d_555_564" x="0.675681" y="0" width="617.492" height="78.1261" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="7.32432"/>
<feGaussianBlur stdDeviation="3.66216"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_555_564"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_555_564" result="shape"/>
</filter>
<linearGradient id="paint0_linear_555_564" x1="62.9321" y1="20.7522" x2="62.9321" y2="42.7252" gradientUnits="userSpaceOnUse">
<stop stop-color="#9EFF00"/>
<stop offset="0.489583" stop-color="#28F472"/>
<stop offset="1" stop-color="#A3E0A5"/>
</linearGradient>
<linearGradient id="paint1_linear_555_564" x1="62.9321" y1="20.7522" x2="62.9321" y2="42.7252" gradientUnits="userSpaceOnUse">
<stop stop-color="#9EFF00"/>
<stop offset="0.489583" stop-color="#28F472"/>
<stop offset="1" stop-color="#A3E0A5"/>
</linearGradient>
<linearGradient id="paint2_linear_555_564" x1="62.9321" y1="20.7522" x2="62.9321" y2="42.7252" gradientUnits="userSpaceOnUse">
<stop stop-color="#9EFF00"/>
<stop offset="0.489583" stop-color="#28F472"/>
<stop offset="1" stop-color="#A3E0A5"/>
</linearGradient>
<linearGradient id="paint3_linear_555_564" x1="62.9321" y1="20.7522" x2="62.9321" y2="42.7252" gradientUnits="userSpaceOnUse">
<stop stop-color="#9EFF00"/>
<stop offset="0.489583" stop-color="#28F472"/>
<stop offset="1" stop-color="#A3E0A5"/>
</linearGradient>
<clipPath id="clip0_555_564">
<rect width="197.665" height="63.4774" fill="white" transform="translate(8)"/>
</clipPath>
</defs>
</svg>
`;
