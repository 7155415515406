import { Link, useParams } from 'react-router-dom';
import styles from './StreamControl.module.scss';
import {
  useSetStreamMetadata,
  useSetStreamScore,
  useSetStreamTimer,
  useStreamScoreboard,
} from '../useStreamScoreboard';
import { ReactComponent as bannerAdPreviewSVG } from '../assets/banner-ad-preview.svg';
import { ReactComponent as smallBannerAdPreviewSVG } from '../assets/small-banner-ad-preview.svg';
import inputStyles from './Input.module.scss';

import { TeamStreamControl } from './TeamStreamControl';
import { Button, Input, Text, Toggle } from '@veo/web-design-system';
import { Skeleton } from '../shared/Skeleton';
import { TimerCount } from './TimerCount';
import { useStream } from '../useStream';
import { PromotionalOverlaySection } from './PromotionalOverlaySection';
import { usePromotionalOverlayVisibility } from './useStreamPromotionalOverlay';
import classNames from 'classnames';
import { ScoreboardPreview } from './ScoreboardPreview';
import { UnauthorizedToEditStream } from './UnauthorizedToEditStream';

export function StreamControl() {
  const { streamId } = useParams();

  const {
    scoreboard,
    isLoading: isScoreboardLoading,
    secondsElapsed,
    unauthorized: unauthorizedForScoreboard,
  } = useStreamScoreboard(streamId as string);
  const { stream, isLoading: isStreamLoading } = useStream(streamId as string);
  const { setStreamScore } = useSetStreamScore(streamId as string);
  const { setStreamTimer } = useSetStreamTimer(streamId as string);
  const {
    setTeamShortNameHome,
    setTeamShortNameAway,
    setScoreboardVisibility,
  } = useSetStreamMetadata(streamId as string);
  const {
    isLoading: isPromotionalOverlaysLoading,
    smallImageTopRight,
    bannerBottomCenter,
    unauthorized: unauthorizedForOverlays,
  } = usePromotionalOverlayVisibility(streamId as string);

  function setHomeScore(home: number) {
    setStreamScore(
      scoreboard?.score
        ? {
            ...scoreboard.score,
            home,
          }
        : {
            home,
            away: 0,
          },
    );
  }

  function setAwayScore(away: number) {
    setStreamScore(
      scoreboard?.score
        ? {
            ...scoreboard.score,
            away,
          }
        : {
            home: 0,
            away,
          },
    );
  }

  if (isScoreboardLoading || isStreamLoading || isPromotionalOverlaysLoading) {
    return (
      <div className={styles['container']}>
        <Skeleton width="100%" height={'47px'} />

        <div className={styles['sub-header-skeleton-container']}>
          <Skeleton width="155px" height={'47px'} />
        </div>

        <Skeleton width="100%" height={'47px'} />

        <div className={styles['split-container']}>
          <div className={styles['split-container-item']}>
            <Skeleton width="100%" height={'190px'} />
          </div>
          <div className={styles['split-container-item']}>
            <Skeleton width="100%" height={'190px'} />
          </div>
        </div>

        <Skeleton width="100%" height={'134px'} />
        <Skeleton width="100%" height={'188px'} />
        <Skeleton width="100%" height={'188px'} />
      </div>
    );
  }

  if (unauthorizedForOverlays || unauthorizedForScoreboard) {
    return <UnauthorizedToEditStream />;
  }

  return (
    <div className={styles['container']}>
      <TimerCount
        timer={scoreboard?.timer}
        setStreamTimer={setStreamTimer}
        stream={stream}
        secondsElapsed={secondsElapsed}
      />

      <div className={styles['split-container']}>
        <div className={styles['split-container-item']}>
          <Input
            type="text"
            label="Home"
            className={classNames({
              [styles['split-input']]: true,
              [inputStyles['input-error']]:
                !scoreboard?.metadata.teamShortName?.home?.length,
            })}
            iconEnd="edit"
            value={scoreboard?.metadata.teamShortName?.home}
            onChange={(evt) =>
              setTeamShortNameHome(evt.target.value.toUpperCase())
            }
            maxLength={3}
          />
        </div>
        <div className={styles['split-container-item']}>
          <Input
            type="text"
            label="Away"
            className={classNames({
              [styles['split-input']]: true,
              [inputStyles['input-error']]:
                !scoreboard?.metadata.teamShortName?.away?.length,
            })}
            iconEnd="edit"
            value={scoreboard?.metadata.teamShortName?.away}
            onChange={(evt) =>
              setTeamShortNameAway(evt.target.value.toUpperCase())
            }
            maxLength={3}
          />
        </div>
      </div>

      <div className={styles['split-container']}>
        <div className={styles['split-container-item']}>
          <TeamStreamControl
            score={scoreboard?.score?.home || 0}
            onChangeScore={setHomeScore}
          />
        </div>
        <div className={styles['split-container-item']}>
          <TeamStreamControl
            score={scoreboard?.score?.away || 0}
            onChangeScore={setAwayScore}
          />
        </div>
      </div>

      <div className={classNames(styles['scoreboard-visibility-container'])}>
        <Text
          size={'overline'}
          weight="bold"
          className={styles['scoreboard-visibility-text']}
          // eslint-disable-next-line i18next/no-literal-string
        >
          Scoreboard visibility
        </Text>
        <ScoreboardPreview
          visible={!!scoreboard?.metadata.scoreboardVisible}
          score={scoreboard?.score}
          teamShortName={scoreboard?.metadata.teamShortName}
          secondsElapsed={secondsElapsed}
          isClockRunning={scoreboard?.timer?.action === 'start'}
        />
        <Toggle
          label={scoreboard?.metadata.scoreboardVisible ? 'On' : 'Off'}
          checked={scoreboard?.metadata.scoreboardVisible}
          onChange={() =>
            setScoreboardVisibility(!scoreboard?.metadata.scoreboardVisible)
          }
        />
      </div>

      <PromotionalOverlaySection
        title="Banner ad visibility"
        ImgSVG={bannerAdPreviewSVG}
        enabled={bannerBottomCenter.isVisible}
        onChange={bannerBottomCenter.setVisibility}
        hasImage={bannerBottomCenter.exists}
      />

      <PromotionalOverlaySection
        title="Small banner ad visibility"
        ImgSVG={smallBannerAdPreviewSVG}
        enabled={smallImageTopRight.isVisible}
        onChange={smallImageTopRight.setVisibility}
        hasImage={smallImageTopRight.exists}
      />

      <Link to={'/'} className={styles['stream-list-button']}>
        <Button
          size={'lg'}
          label={'Stream list'}
          variant={'outlined'}
          className={styles['stream-list-button']}
        />
      </Link>
    </div>
  );
}
