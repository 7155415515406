import { Icon, Text } from '@veo/web-design-system';
import { useStreamsToEditScoreboard } from '../useStreamScoreboard';
import styles from './StreamsList.module.scss';
import {
  StreamListEntry,
  StreamListEntrySkeletonLoader,
} from './StreamListEntry';
import { Header } from '../Header';

export function StreamsList() {
  return (
    <div className={styles['container']}>
      <Header />
      <div className={styles['body-container']}>
        <div className={styles['live-text-container']}>
          <Icon name={'rss'} size={'xs'} />
          <Text
            size="caption"
            weight={'semiBold'}
            className={styles['live-text']}
          >
            {'Live games'}
          </Text>
        </div>
        <StreamsListBody />
      </div>
    </div>
  );
}

export function StreamsListBody() {
  const { streams, isLoading } = useStreamsToEditScoreboard();

  if (isLoading) {
    return (
      <div className={styles['streams-list-container-skeleton']}>
        <StreamListEntrySkeletonLoader />
        <StreamListEntrySkeletonLoader />
        <StreamListEntrySkeletonLoader />
      </div>
    );
  }

  return (
    <div className={styles['streams-list-container']}>
      {streams.length ? (
        streams.map((stream) => (
          <StreamListEntry key={stream.streamId} stream={stream} />
        ))
      ) : (
        <Text size={'overline'} className={styles['no-live-games-text']}>
          {'No live games detected'}
        </Text>
      )}
    </div>
  );
}
