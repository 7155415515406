import { EMPTY_CLOCK_LABEL, getClockLabel } from './timerUtils';
import {
  scoreboardPreviewPlaceholder,
  scoreboardPreviewSvgAsString as scoreboardPreviewSvgAsStringBase,
} from './scoreboardPreviewSvgAsString';
import classNames from 'classnames';
import styles from './ScoreboardPreview.module.scss';

type ScoreboardPreviewProps = {
  visible: boolean;
  score?: {
    home: number;
    away: number;
  };
  teamShortName?: {
    home: string;
    away: string;
  };
  secondsElapsed: number;
  isClockRunning: boolean;
};

export function ScoreboardPreview({
  visible,
  score,
  teamShortName,
  secondsElapsed,
  isClockRunning,
}: ScoreboardPreviewProps) {
  function generateScoreboardPreview() {
    return scoreboardPreviewSvgAsStringBase
      .replace(
        scoreboardPreviewPlaceholder.clock,
        isClockRunning ? getClockLabel(secondsElapsed) : EMPTY_CLOCK_LABEL,
      )
      .replace(
        scoreboardPreviewPlaceholder.teamShortName.home,
        teamShortName?.home || 'HOM',
      )
      .replace(
        scoreboardPreviewPlaceholder.teamShortName.away,
        teamShortName?.away || 'AWA',
      )
      .replace(
        scoreboardPreviewPlaceholder.score.home,
        score?.home.toString() || '0',
      )
      .replace(
        scoreboardPreviewPlaceholder.score.away,
        score?.away.toString() || '0',
      );
  }

  const scoreboardPreviewSvgAsString = generateScoreboardPreview();

  return (
    <div
      className={classNames({ [styles['not-visible']]: !visible })}
      dangerouslySetInnerHTML={{ __html: scoreboardPreviewSvgAsString }}
    />
  );
}
