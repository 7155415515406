import { StreamSummary, TeamForScoreboardList } from '@libs/models/stream';
import { Button, Icon, IconName, Text } from '@veo/web-design-system';
import styles from './StreamListEntry.module.scss';
import { Link } from 'react-router-dom';
import { Skeleton } from '../shared';
import classNames from 'classnames';

type StreamListEntryProps = {
  stream: StreamSummary;
};

export function StreamListEntrySkeletonLoader() {
  return <Skeleton width={'20.4375rem'} height={'8rem'} />;
}

export function StreamListEntry({ stream }: StreamListEntryProps) {
  return (
    <div className={styles['container']}>
      <StreamListEntryTeam team={stream.home} />
      <div className={styles['stream-destination-container']}>
        {stream.destinationIcon && (
          <Icon name={stream.destinationIcon as IconName} size="sm" />
        )}
        <Text size="overline" className={styles['destination-text']}>
          {stream.destinationName
            ? `Streaming on ${stream.destinationName}`
            : 'Externally streaming'}
        </Text>
        <Link to={`/stream/${stream.streamId}`}>
          <Button variant="outlined" size="sm" label={'Control'} />
        </Link>
      </div>
      <StreamListEntryTeam team={stream.away} crestFirst />
    </div>
  );
}

function StreamListEntryTeam({
  team,
  crestFirst = false,
}: {
  team: TeamForScoreboardList;
  crestFirst?: boolean;
}) {
  return (
    <div
      className={classNames({
        [styles['team-container']]: true,
        [styles['team-container__away']]: crestFirst,
      })}
    >
      {crestFirst && (
        <StreamListEntryTeamCrest clubCrestUrl={team.clubCrestUrl} />
      )}
      <div className={styles['team-name-container']}>
        <Text size="body" weight={'semiBold'}>
          {team.clubName}
        </Text>
        {team.teamName && <Text size="overline">{team.teamName}</Text>}
      </div>
      {!crestFirst && (
        <StreamListEntryTeamCrest clubCrestUrl={team.clubCrestUrl} />
      )}
    </div>
  );
}

function StreamListEntryTeamCrest({ clubCrestUrl }: { clubCrestUrl?: string }) {
  return clubCrestUrl ? (
    <img
      className={styles['crest-img']}
      src={clubCrestUrl}
      alt={'Club crest'}
    />
  ) : (
    <Icon name="shield" size="sm" />
  );
}
