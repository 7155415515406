import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchFromLiveService } from '../liveService';
import { ToastVariant, useToasts } from '@veo/web-design-system';
import {
  StreamPromotionalOverlaysState,
  StreamPromotionalOverlayState,
  StreamPromotionalOverlayType,
} from '@libs/models/stream';
import { UnauthorizedError } from '../error';
import { useState } from 'react';

const DEFAULT_ERROR_TOAST_PROPS = {
  variant: 'error' as ToastVariant,
  title: 'Update failed',
  description: 'Something went wrong, try again or refresh the page.',
};

function getStreamPromotionalOverlayVisibilityQueryKey(
  streamId: string,
): string {
  return `streamScoreboard/${streamId}/promotional-overlays`;
}

export type UsePromotionalOverlayState = {
  exists: boolean;
  isVisible: boolean;
  isSetVisibilityLoading: boolean;
  setVisibility: (isVisible: boolean) => void;
};

export function usePromotionalOverlayVisibility(streamId: string): {
  isLoading: boolean;
  smallImageTopRight: UsePromotionalOverlayState;
  bannerBottomCenter: UsePromotionalOverlayState;
  unauthorized: boolean;
} {
  const { add: addToast } = useToasts();
  const queryClient = useQueryClient();
  const [unauthorized, setUnauthorized] = useState(false);

  function onErrorSetVisibility(
    _: unknown,
    __: unknown,
    context: { previousData: StreamPromotionalOverlaysState },
  ) {
    addToast(DEFAULT_ERROR_TOAST_PROPS);
    queryClient.setQueryData(
      getStreamPromotionalOverlayVisibilityQueryKey(streamId),
      context.previousData,
    );
  }

  const { isLoading, data } = useQuery<StreamPromotionalOverlaysState>({
    queryKey: getStreamPromotionalOverlayVisibilityQueryKey(streamId),
    queryFn: () =>
      fetchFromLiveService<void, StreamPromotionalOverlaysState>(
        `streams/${streamId}/events/promotional-overlays`,
      ),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 1,
    onError: (error) => {
      if (error instanceof UnauthorizedError) {
        setUnauthorized(true);
        return;
      }

      addToast(DEFAULT_ERROR_TOAST_PROPS);
    },
  });

  const {
    mutateAsync: setBannerBottomCenterVisibilityMutateAsync,
    isLoading: isSetBannerBottomCenterVisibilityLoading,
  } = useMutation({
    mutationFn: (newPromotionalOverlay: StreamPromotionalOverlayState) =>
      fetchFromLiveService(
        `streams/${streamId}/events/promotional-overlays`,
        'PUT',
        newPromotionalOverlay,
      ),
    onMutate: optimisticallyUpdatePromotionalOverlayVisibility,
    onError: onErrorSetVisibility,
  });

  const {
    mutateAsync: setSmallImageTopRightVisibilityMutateAsync,
    isLoading: isSetSmallImageTopRightVisibilityLoading,
  } = useMutation({
    mutationFn: (newPromotionalOverlay: StreamPromotionalOverlayState) =>
      fetchFromLiveService(
        `streams/${streamId}/events/promotional-overlays`,
        'PUT',
        newPromotionalOverlay,
      ),
    onMutate: optimisticallyUpdatePromotionalOverlayVisibility,
    onError: onErrorSetVisibility,
  });

  function optimisticallyUpdatePromotionalOverlayVisibility(
    update: StreamPromotionalOverlayState,
  ) {
    const queryKey = getStreamPromotionalOverlayVisibilityQueryKey(streamId);
    const previousData = queryClient.getQueryData(queryKey);

    queryClient.setQueryData(
      queryKey,
      (
        previousData: StreamPromotionalOverlaysState,
      ): StreamPromotionalOverlaysState => {
        return {
          ...previousData,
          streamPromotionalOverlays: [
            ...previousData.streamPromotionalOverlays.filter(
              (streamPromotionalOverlay) =>
                streamPromotionalOverlay.type !== update.type,
            ),
            update,
          ],
        };
      },
    );

    return { previousData };
  }

  function setBannerBottomCenterVisibility(
    promotionalOverlayId: string,
    visible: boolean,
  ) {
    setBannerBottomCenterVisibilityMutateAsync({
      id: promotionalOverlayId,
      type: 'bannerBottomCenter',
      status: visible ? 'start' : 'stop',
      timestamp: Date.now(),
    });
  }

  function setSmallImageTopRightVisibility(
    promotionalOverlayId: string,
    visible: boolean,
  ) {
    setSmallImageTopRightVisibilityMutateAsync({
      id: promotionalOverlayId,
      type: 'smallImageTopRight',
      status: visible ? 'start' : 'stop',
      timestamp: Date.now(),
    });
  }

  return {
    isLoading,
    smallImageTopRight: {
      exists: doesOverlayExist(
        data?.streamPromotionalOverlays,
        'smallImageTopRight',
      ),
      isVisible: isOverlayVisible(
        data?.streamPromotionalOverlays,
        'smallImageTopRight',
      ),
      isSetVisibilityLoading: isSetSmallImageTopRightVisibilityLoading,
      setVisibility: (visible: boolean) =>
        setSmallImageTopRightVisibility(
          getOverlayId(
            data?.streamPromotionalOverlays,
            'smallImageTopRight',
          ) as string,
          visible,
        ),
    },
    bannerBottomCenter: {
      exists: doesOverlayExist(
        data?.streamPromotionalOverlays,
        'bannerBottomCenter',
      ),
      isVisible: isOverlayVisible(
        data?.streamPromotionalOverlays,
        'bannerBottomCenter',
      ),
      isSetVisibilityLoading: isSetBannerBottomCenterVisibilityLoading,
      setVisibility: (visible: boolean) => {
        setBannerBottomCenterVisibility(
          getOverlayId(
            data?.streamPromotionalOverlays,
            'bannerBottomCenter',
          ) as string,
          visible,
        );
      },
    },
    unauthorized,
  };
}

function doesOverlayExist(
  overlays: StreamPromotionalOverlayState[] = [],
  overlayType: StreamPromotionalOverlayType,
): boolean {
  return overlays.some((overlay) => overlay.type === overlayType);
}

function isOverlayVisible(
  overlays: StreamPromotionalOverlayState[] = [],
  overlayType: StreamPromotionalOverlayType,
): boolean {
  return overlays.some(
    (overlay) => overlay.type === overlayType && overlay.status === 'start',
  );
}

function getOverlayId(
  overlays: StreamPromotionalOverlayState[] = [],
  overlayType: StreamPromotionalOverlayType,
): string | undefined {
  const startedOverlay = overlays.find(
    (overlay) => overlay.type === overlayType && overlay.status === 'start',
  );
  if (startedOverlay) {
    return startedOverlay.id;
  }

  const stoppedOverlay = overlays.find(
    (overlay) => overlay.type === overlayType && overlay.status === 'stop',
  );
  return stoppedOverlay?.id;
}
