// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GlobalStyles, ToastContextProvider } from '@veo/web-design-system';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthRedirect } from './AuthRedirect';
import { AuthenticatedApp } from './AuthenticatedApp';
import { AuthLogout } from './AuthLogout';
import styles from './app.module.scss';

export function App() {
  const queryClient = new QueryClient();

  return (
    <GlobalStyles>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ToastContextProvider
            titles={{
              error: 'Error',
              info: 'Info',
              success: 'Success',
              warning: 'Warning',
            }}
          >
            <div className={styles['container']}>
              <Routes>
                <Route path="/auth-redirect" element={<AuthRedirect />} />
                <Route path="/auth-logout" element={<AuthLogout />} />
                <Route path="*" element={<AuthenticatedApp />} />
              </Routes>
            </div>
          </ToastContextProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </GlobalStyles>
  );
}

export default App;
