import { Button, Text } from '@veo/web-design-system';
import { useAuth } from 'react-oidc-context';
import styles from './UnauthorizedToEditStream.module.scss';

export function UnauthorizedToEditStream() {
  const { signoutRedirect } = useAuth();

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <Text size={'h2'} className={styles['text']}>
          {'Producer Panel blocked'}
        </Text>
        <div className={styles['sub-header']}>
          <Text size={'body'} className={styles['text']}>
            {
              'To control the Producer Panel for this live stream you need to have the Club Admin, Team Admin or Team Editor permission.'
            }
          </Text>
          <Text size={'body'} className={styles['text']}>
            {'Reach out to your Club Admin to get the necessary permissions.'}
          </Text>
        </div>
      </div>

      <Button
        variant={'primary'}
        onClick={() => {
          signoutRedirect();
        }}
        label="Log in with a different account"
      />
    </div>
  );
}
