import * as Sentry from '@sentry/react';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { environment } from './environments/environment';
import { AnalyticsProvider } from '@libs/reactUtils';

import App from './app/app';
import { WebStorageStateStore } from 'oidc-client-ts';

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api-staging\.live\.veo\.co/,
      /^https:\/\/api\.live\.veo\.co/,
    ],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    environment: environment.production ? 'production' : 'staging',
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const authProviderProps: AuthProviderProps = {
  authority: environment.oicd_authority,
  client_id: environment.oicd_client_id,
  redirect_uri: `${window.location.origin}/auth-redirect`,
  post_logout_redirect_uri: `${window.location.origin}/auth-logout`,
  scope: 'openid profile',
  response_type: 'code',
  response_mode: 'fragment',
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  // TODO - to be added when we change to jwt tokens
  // resource: environment.liveAPIEndpoint,
};

root.render(
  <Sentry.ErrorBoundary>
    <StrictMode>
      <AnalyticsProvider configuration={environment}>
        <AuthProvider {...authProviderProps}>
          <App />
        </AuthProvider>
      </AnalyticsProvider>
    </StrictMode>
  </Sentry.ErrorBoundary>,
);
