import { Button, IconButton } from '@veo/web-design-system';
import veoLogo from './assets/veo-logo.svg';
import styles from './Header.module.scss';
import { useState } from 'react';
import classNames from 'classnames';
import { useAuth } from 'react-oidc-context';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { signoutRedirect } = useAuth();

  return (
    <div className={styles['container']}>
      <div
        className={classNames(styles['menu-container'], {
          [styles['show']]: isMenuOpen,
        })}
      >
        <div>
          <Button
            variant={'outlined'}
            onClick={() => {
              signoutRedirect();
            }}
            label="Sign out"
          />
        </div>
      </div>
      <IconButton
        icon={isMenuOpen ? 'x' : 'menu'}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        variant={'text'}
        size={'md'}
      />
      <img src={veoLogo} alt="Veo logo" />
    </div>
  );
}
